import { createApp } from 'vue'
import { router } from './router/index';
import { store } from './store/index';
import App from './App.vue'
import axios from "axios";

const API_URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL : "http://localhost:8989";

const axios_instance = axios.create({
    baseURL: API_URL,
});

const serialize_param = function (obj, ignore_null_empty = true) {
    /*
    if (typeof obj === 'undefined' || obj == null) {
        return "";
    }
    */
    const arr = Array.isArray(obj) ? obj : [obj];
    const str = arr
        .filter(v => (v != null))
        .reduce((acc, i) => {
            const tmp = [];
            for (const [k, v] of Object.entries(i)) {
                if (ignore_null_empty && (v == null || v === '')) {
                    continue;
                }
                tmp.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
            }
            return [...acc, ...tmp];
        }, [])
        .join("&");
    return `?${str}`;
};
class XIOBase {
    constructor(base_path) {
        this.base_path = base_path;
    }

    get(dict = null) {
        const params = serialize_param(dict);
        return axios_instance.get(`${this.base_path}${params}`);
    }

    get_one(id, dict = null) {
        const params = serialize_param(dict);
        return axios_instance.get(`${this.base_path}/${id}${params}`);
    }

    post(dict) {
        const form = new FormData();
        for (const [key, value] of Object.entries(dict)) {
            if (Array.isArray(value)) {
                value.forEach(v => {
                    form.append(`${key}`, v);
                });
            } else {
                form.append(`${key}`, value);
            }
        }
        return axios_instance.post(`${this.base_path}`, form);
    }

    put(id, dict) {
        const form = new FormData();
        for (const [key, value] of Object.entries(dict)) {
            form.append(`${key}`, value);
        }
        return axios_instance.put(`${this.base_path}/${id}`, form);
    }

    delete(id) {
        return axios_instance.delete(`${this.base_path}/${id}`);
    }
}
function get_country() {
    return axios.get("https://api.ip.pe.kr/json");
}

const app = createApp(App)
app.use(router)
app.use(store)
app.config.globalProperties.$axios_instance = axios_instance;
app.config.globalProperties.$send_email = new XIOBase('/v2/auth/send-email-henergy');
app.config.globalProperties.$get_country = get_country;
app.mount('#app')